.theme-nova {

  body .p-component {
    font-family: var(--lc2-font-family-center);
    font-size: 0.875rem;

    p {
      line-height: 1.5;
      margin: 0;
    }
  }

  body .p-accordion {
    .p-accordion-toggle-icon {
      background-color: transparent;
      border: 1px solid #EAEAEA;
      border-radius: 2px;
      color: var(--lc2-color-primary);
      z-index: 1;
      cursor: pointer;
      width: 16px;
      &:hover {
        background-color: var(--lc2-color-secondary);
        color: var(--lc2-color-primary);
      }
      .p-icon {
        transform: scale(.8);
      }
    }
  }

  body p-button.p-disabled {
    pointer-events: all; // for backwards compatibility...
  }

  body .p-button {
    border-radius: 12px;
    padding: 0 12px;
    text-transform: none;

    .p-button-label {
      font-size: .75rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }

  body .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
  }

  body .p-button .p-button-icon-left,
  body .p-button .p-button-icon-right {
    font-size: 10px;
    background-color: white;
    border-radius: 8px;
    color: var(--lc2-color-primary);
    text-align: center;
    width: 16px;
    line-height: 16px !important;

  }
  body .p-button .p-button-icon-left {
    margin-left: -4px;
  }
  body .p-button .p-button-icon-right {
    margin-right: -4px;
  }
  body .p-button.p-button-icon-only .p-button-icon {
    line-height: 22px;
  }
  body .p-button.p-button-icon-only:after {
    content: unset;
  }

  body .p-button.p-button-primary {
    &.p-button-text {
      background-color: transparent;
      color: var(--lc2-color-primary);
      padding: 0 6px;
      font-size: .75rem;
    }
    &.p-button-text:enabled:hover {
      background-color: var(--lc2-color-secondary);
      border: 1px solid var(--lc2-color-secondary);
      color: var(--lc2-color-primary);
    }

    &:not(.p-button-text) {
      background-color: var(--lc2-color-primary);
      border: 1px solid var(--lc2-color-primary);
      color: white;
    }
    &:not(.p-button-text):enabled:hover {
      background-color: var(--lc2-color-primary);
      border: 1px solid var(--lc2-color-secondary);
      color: white;
    }
  }

  body .p-button.p-button-secondary {
    &.p-button-outlined {
      background-color: white;
      border: 0;
      border-radius: 6px;
      color: var(--lc2-color-primary);
      font-size: .75rem;
      text-align: center;
      width: 24px;

      .p-button-icon {
        line-height: 24px;
      }
    }
    &.p-button-outlined:enabled:hover {
      background-color: var(--lc2-color-secondary);
      color: var(--lc2-color-primary);
    }

    &:not(.p-button-outlined) {
      background-color: var(--lc2-color-secondary);
      border: 1px solid var(--lc2-color-secondary);
      color: var(--lc2-color-primary);
    }
    &:not(.p-button-outlined):enabled:hover {
      background-color: var(--lc2-color-secondary);
      border-color: var(--lc2-color-primary);
      color: var(--lc2-color-primary);
    }

  }


  body .p-button.p-button-secondary:enabled:focus, body .p-buttonset.p-button-secondary > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
  }
  body .p-button.p-button-secondary:enabled:active, body .p-buttonset.p-button-secondary > .p-button:enabled:active {
    background-color: #a0a0a0;
    color: #333333;
    border-color: #a0a0a0;
  }
  body .p-button.p-button-danger {
    .p-button-icon-left,
    .p-button-icon-right {
      color: #e91224;
    }
  }

  body .p-dialog {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 7px 20px #00000066;

    .p-dialog-header {
      background: white;
      border-bottom: none;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      padding: .5rem .5rem .5rem 1rem;
      .p-dialog-header-icon {
        border-radius: 6px;
        &:enabled:hover {
          background-color: var(--lc2-color-secondary);
          border-radius: 6px;
          color: var(--lc2-color-primary);
        }
      }
    }

    .p-dialog-content {
      background-color: var(--lc2-color-background-secondary);
      border: 1px solid #F2F2F2;
      border-radius: 6px;
      margin: 0 .5rem;
      padding: .5rem;
      position: relative;
    }

    .p-dialog-footer {
      background: white;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top: none;
      padding: .5rem .5rem .5rem 1rem;
      text-align: unset;

      button {
        margin: 0;
      }
    }
  }

  body .p-fileupload-choose {
    .p-icon-wrapper {
      display: none;
    }
  }

  body .p-float-label {
    height: 100%;
    padding-top: 12px;

    > label {
      margin-top: 0px;
      margin-left: 8px;
      font-size: .75rem;
      font-weight: 600;
      color: #999999;
    }

    input:focus ~ label,
    input.p-filled ~ label,
    textarea:focus ~ label,
    textarea.p-filled ~ label,
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label {
      top: 0;
      left: 0;
      font-size: 10px;
    }
  }

  body p-galleria {
    .p-galleria-item-container {
      overflow: hidden;
      img {
        border-radius: 8px;
      }
    }

    .p-galleria-content {
      height: 100%;
      p-galleriaitem {
        flex: 1;
        height: 100%;
        .p-galleria-item-wrapper {
          height: 100%;
          .p-galleria-item-container {
            .p-galleria-item {
              position: relative;
            }

            .p-galleria-item-nav {
              background: transparent;
              z-index: 1;
              &:hover {
                background: rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }
    }

    .p-galleria-thumbnail-container {
      background: transparent;
      .p-galleria-thumbnail-item {
        overflow: hidden;
        > div {
          position: relative;
          background-color: white;
          border-radius: 8px;
          width: 64px;
          height: 60px;
        }
      }
    }
  }

  body p-listbox {
    min-height: 0;
    width: 100%;

    .p-listbox {
      border: 1px solid var(--lc-color-gray);
      overflow: auto;
      padding: 0;
      height: 100%;
      width: 100%;

      .p-listbox-filter-container {
        input {
          background-color: #E6E6E6;
          border: 0 !important;
          border-radius: 12px !important;
          color: var(--lc2-color-foreground);
          padding: 0 32px 0 12px !important;
          font-size: 0.75rem !important;
          font-weight: 600;
          line-height: 24px !important;
          width: 100%;
          box-sizing: border-box !important;

          &:focus {
            box-shadow: none;
          }
        }

        .p-icon-wrapper {
          position: absolute;
          bottom: 4px;
          right: 10px;
          background-color: white;
          border-radius: 8px;
          z-index: 1;
          width: 16px;
          height: 16px;
          .p-listbox-filter-icon {
            right: 0;
            color: var(--lc2-color-primary);
            transform: scale(.6);
          }
        }
      }

      .p-listbox-list-wrapper {
        flex: 1;
        min-height: 0;
        max-height: unset !important;

        .p-listbox-item {
          color: var(--lc2-color-foreground);

          &.p-highlight {
            background-color: #CEE3FA;
            color: #202020;
            font-weight: 500;
          }
        }
      }
    }
  }

  body .p-sidebar .p-sidebar-header .p-sidebar-close {
    border-radius: 6px;
    &:enabled:hover {
      background-color: var(--lc2-color-secondary);
      border-radius: 6px;
      color: var(--lc2-color-primary);
    }
  }

  body p-splitbutton {
    &[severity="primary"] {
      background-color: var(--lc2-color-primary);
      border: 1px solid var(--lc2-color-primary);
      border-radius: 12px;
      color: white;
    }
    &[severity="primary"]:hover {
      border: 1px solid var(--lc2-color-secondary);
    }

    &[severity="secondary"] {
      background-color: var(--lc2-color-secondary);
      border: 1px solid var(--lc2-color-secondary);
      border-radius: 12px;
      color: var(--lc2-color-primary);
    }
    &[severity="secondary"]:hover {
      border: 1px solid var(--lc2-color-primary);
    }

    .p-splitbutton-defaultbutton {
      padding-right: 0;
      &:hover {
        border: inherit !important;
      }
    }
    .p-splitbutton-menubutton {
      padding: 0;
      &:hover {
        border: inherit !important;
      }
    }
    .p-icon-wrapper {
      background-color: white;
      border-radius: 8px;
      color: var(--lc2-color-primary);
      z-index: 1;
      cursor: pointer;
      width: 16px;
      .p-icon {
        transform: scale(.5);
      }
    }
  }

  body .p-tree {
    border: 0;
    padding: 0;

    .p-tree-filter-container {
      input {
        background-color: #E6E6E6;
        border: 0 !important;
        border-radius: 12px !important;
        color: var(--lc2-color-foreground);
        padding: 0 32px 0 12px !important;
        font-size: 0.75rem !important;
        font-weight: 600;
        line-height: 24px !important;
        width: 100%;
        box-sizing: border-box !important;

        &:focus {
          box-shadow: none;
        }
      }

      .p-icon-wrapper {
        position: absolute;
        bottom: 4px;
        right: 10px;
        background-color: white;
        border-radius: 8px;
        z-index: 1;
        width: 16px;
        height: 16px;
        .p-tree-filter-icon {
          right: 0;
          color: var(--lc2-color-primary);
          transform: scale(.6);
        }
      }
    }

    .p-treenode-content {
      .p-tree-toggler .p-icon-wrapper {
        background-color: transparent;
        border: 1px solid #EAEAEA;
        border-radius: 2px;
        color: var(--lc2-color-primary);
        z-index: 1;
        cursor: pointer;
        width: 16px;
        &:hover {
          background-color: var(--lc2-color-secondary);
          color: var(--lc2-color-primary);
        }
        .p-icon {
          transform: scale(.7);
        }
      }

      &.p-highlight {
        .p-tree-toggler .p-icon-wrapper {
          color: white;
          &:hover {
            background-color: var(--lc2-color-secondary);
            color: var(--lc2-color-primary);
          }
        }
      }
    }
  }

  // I haven't reviewed the following styles after the primeng 17 upgrade

  body .p-card {
    position: relative;
    margin: 0.375rem;
    border-radius: 0;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);

    height: calc(100% - .75rem);
    padding: .5rem;

    display: flex;
    flex-direction: column;

    .p-card-header {
      font-size: 1.5rem;
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: visible;
      color: #46535A;
    }

    .p-card-body {
      flex: 1;
      min-height: 0;
      min-width: 0;
      padding: .5rem;
      overflow-y: auto;

      display: flex;
      flex-direction: column;

      .p-card-content {
        flex: 1;
        min-height: 0;
        min-width: 0;
        padding: 0;
        position: relative;
      }
    }
  }

  body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #46535A;
    background-color: transparent;
  }

  body .p-tabview {
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 2px;

    .p-tabview-nav li {
      .p-tabview-nav-link {
        background: white;
        border: 1px solid var(--lc2-color-background-primary);
        border-radius: 4px;
        color: var(--lc2-color-foreground);
        margin: 0;
        padding: 4px 8px;

        .p-tabview-title {
          font-size: 0.75rem;
          font-weight: 600;
          line-height: normal;
        }

        .p-tabview-close {
          width: 8px;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background: var(--lc2-color-primary);
          color: white;
        }
      }

      &:not(.p-highlight):hover {
        .p-tabview-nav-link {
          background: var(--lc2-color-secondary);
          color: var(--lc2-color-primary);
        }
      }
    }

    .p-tabview-panels {
      border: 1px solid var(--lc2-color-background-primary);
      border-radius: 6px;
      flex: 1;
      height: calc(100% - .9rem - 2em);
      min-height: 0;
      min-width: 0;
      padding: 0;

      .p-tabview-panel {
        background-color: white;
        border-radius: 6px;
        overflow: auto; // this adds scroll to the panel. comment this and uncomment the min-height above to let it grow and the scroll will be handled by the parent container
        padding: 0.5rem;
        width: 100%;
        height: 100%;
      }
      .no-padding {
        > .p-tabview-panel {
          padding: 0;
        }
      }
    }
  }

  body .p-tabview.underline {
    .p-tabview-nav-content {
      scroll-padding-inline: 3rem;
    }
    .p-tabview-nav {
      border: 1px solid #e5e7eb;
      border-width: 0 0 2px 0;
    }
    .p-tabview-nav li {
      margin-right: 0;
    }
    .p-tabview-nav li .p-tabview-nav-link {
      border: solid transparent;
      border-width: 0 0 2px 0;
      background: transparent;
      color: #6b7280;
      padding: 0.5rem 1rem;
      font-weight: 700;
      border-radius: 0;
      transition: box-shadow 0.2s;
    }
    .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #BFDBFE;
    }
    .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      background: transparent;
      border-color: #9ca3af;
      color: black;
    }
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      border-color: #3B82F6;
      color: black;
    }
    .p-tabview-left-icon {
      margin-right: 0.5rem;
    }
    .p-tabview-right-icon {
      margin-left: 0.5rem;
    }
    .p-tabview-close {
      margin-left: 0.5rem;
    }
    .p-tabview-nav-btn.p-link {
      background: #ffffff;
      color: #3B82F6;
      width: 3rem;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border-radius: 0;
    }
    .p-tabview-nav-btn.p-link:focus-visible {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #BFDBFE;
    }
    .p-tabview-panels {
      background: #ffffff;
      padding: 0;
      border: 0 none;
      color: #4b5563;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;

      .p-tabview-panel {
        background-color: var(--lc2-color-background-secondary);
      }
    }
  }

  body .p-menu {
    width: auto;
  }

  body .p-dropdown {
    min-width: 5em;
    width: unset;
  }

  body .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container {
    input {
      background-color: #E6E6E6;
      border: 0 !important;
      border-radius: 12px !important;
      color: var(--lc2-color-foreground);
      padding: 0 32px 0 12px !important;
      font-size: 0.75rem !important;
      font-weight: 600;
      line-height: 24px !important;
      width: 100%;
      box-sizing: border-box !important;

      &:focus {
        box-shadow: none;
      }
    }

    .p-icon-wrapper {
      position: absolute;
      bottom: 4px;
      right: 10px;
      background-color: white;
      border-radius: 8px;
      z-index: 1;
      width: 16px;
      height: 16px;
      .p-dropdown-filter-icon {
        right: 0;
        color: var(--lc2-color-primary);
        transform: scale(.6);
      }
    }
  }

  body .p-contextmenu,
  body .p-tieredmenu {
    min-width: 12.5em;
    width: unset;
  }

  body .p-contextmenu,
  body .p-menu,
  body .p-tieredmenu {
    .p-menuitem,
    .p-menuitem-text {
      white-space: nowrap;
    }
  }

  body .p-tooltip {
    max-width: 80vw !important;
  }

  body .p-dropdown-items-wrapper {
    max-height: 400px !important;
  }

  body .p-progressbar {
    background-color: transparent;
  }

  body .p-accordion {
    .p-accordion-header {
      margin-bottom: 0;
      color: #007ad9;

      &:focus,
      a:focus {
        box-shadow: none;
      }

      &:not(.p-disabled).p-highlight a,
      &:not(.p-disabled).p-highlight:hover a {
        color: inherit;
        background-color: inherit;
        border: 1px solid #c8c8c8;
        border-bottom-width: 0;
        .p-accordion-toggle-icon {
          color: inherit;
        }
      }
    }

    .p-accordion-content {
      background-color: transparent;
      border-top-width: 0;
    }
  }


}
