/* You can add global styles to this file, and also import other style files */
$fa-font-path: '../../../assets/fonts/fa/webfonts' !default;
@import './fa/scss/fontawesome.scss';
@import './fa/scss/duotone.scss';
@import './fa/scss/_duotone-icons.scss';
@import './fa/scss/light.scss';
@import './fa/scss/regular.scss';
@import './fa/scss/solid.scss';
@import './fa/scss/thin.scss';

@import 'iv-viewer/dist/iv-viewer.css';
@import 'intl-tel-input/build/css/intlTelInput.css';

@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.min.css';

@import 'angular-calendar/scss/angular-calendar.scss';
@import "jsoneditor/dist/jsoneditor.min.css";

@import "ag-grid-enterprise/styles/ag-grid.css";
@import "ag-grid-enterprise/styles/ag-theme-balham.css";
@import "ag-grid-enterprise/styles/ag-theme-alpine.css";

@import './flex.scss';
@import './oems.scss';

@import 'primeng/resources/themes/nova/theme.css';
@import './primeng-nova-overrides.scss';


@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  font-style: normal;
  src: local('Avenir Medium'), local('Avenir-500'),
    url('../assets/fonts/avenir/AvenirLTStd-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  font-style: normal;
  src: local('Avenir Heavy'), local('Avenir-700'),
    url('../assets/fonts/avenir/AvenirLTStd-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  font-style: normal;
  src: local('Avenir Black'), local('Avenir-900'),
    url('../assets/fonts/avenir/AvenirLTStd-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Lexend';
  src: url('../assets/fonts/lexend/Lexend[HEXP,wght].ttf');
  font-weight: 100 900;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/inter/Inter-VariableFont_opsz,wght.ttf'),
       url('../assets/fonts/inter/Inter-Italic-VariableFont_opsz,wght.ttf');
  font-weight: 100 900;
}

// SourceSansPro is used mainly in the studio
@font-face {
  font-family: SourceSansPro-Light;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/SourceSansPro-Light.eot'),
       url('../assets/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/SourceSansPro-Light.woff') format('woff'),
       url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype'),
       url('../assets/fonts/SourceSansPro-Light.svg#SourceSansPro-light') format('svg');
}

@font-face {
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/sourcesanspro-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/sourcesanspro-regular-webfont.woff') format('woff'),
       url('../assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}


@media screen and (max-width: 319px) {
  html {
    font-size: 12px;
  }
}
@media screen and (min-width: 320px) {
  html {
    font-size: 13px;
  }
}
@media screen and (min-width: 641px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 961px) {
  html {
    font-size: 15px;
  }
}
@media screen and (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

$navbar-height: 64px;


/* Edge HACK for lists / datatables to NOT expand over the limit */
@supports (-ms-ime-align:auto) {
  .card {
    height: 98% !important;
  }
}
/* IE HACKS for lists / datatables to NOT expand over the limit */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card {
    height: 98% !important;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
    overflow: visible !important;
    width: 210mm;
    height: 297mm;
  }
  .print-wrapper {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    // page-break-after: always;
  }
}


.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

html {
  background-color: var(--lc2-color-background-primary);
}

*[hidden] {
  display: none !important;
}

.ag-theme-balham {
  --ag-background-color: var(--lc2-color-background-secondary);
  --ag-disabled-foreground-color: var(--lc2-color-foreground-disabled);
  --ag-odd-row-background-color: #efefef;
  // --ag-row-hover-color: var(--lc2-color-secondary);
  --ag-selected-row-background-color: var(--lc2-color-secondary); // #e0e0e0;
}

body {
  --lc-topbar-height: 64px;
  --lc-sidebar-width: 64px;

  margin: 0px;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;

  .bold {
    font-weight: bold;
  }

  em {
		background-color: #fdcb18;
		font-style: normal !important;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .icon-indent-1 {
    width: 1rem;
  }
  .icon-indent-2 {
    width: 2rem;
  }
  .icon-indent-3 {
    width: 3rem;
  }
  .icon-indent-4 {
    width: 4rem;
  }
  .icon-indent-5 {
    width: 5rem;
  }
  .icon-indent-6 {
    width: 6rem;
  }

  .child-section {
    background-color: var(--lc2-color-background-secondary);
    border-radius: 6px;
    border: 1px solid #F2F2F2;
    position: relative;

    &.inner {
      background-color: var(--lc2-color-background-primary);
    }
  }

  .label {
    color: var(--lc2-color-foreground);
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 1px;
  }

  .link {
    cursor: pointer;
    text-decoration: none;
    // color: #2399e5;
    // color: #007ad9;
    color: var(--lc2-color-primary);

    &.underline {
      text-decoration: underline;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: 0 none;
    }

    &:hover {
      color: #87BFC5;
    }
  }

  .width-99 {
    width: 99% !important;
    max-width: 99%;
  }
  .width-100 {
    width: 100% !important;
    max-width: 100%;
  }

  .height-100 {
    height: 100%;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    margin: 0;
  }

  .ta-center {
    text-align: center;
  }

  .ta-end {
    text-align: end;
  }

  .pointer,
  .cursor-pointer {
    cursor: pointer;
  }

  .hidden {
    display: none !important;
  }

  .section {
    border: 1px dotted var(--lc-color-lightgray);
    padding: .5rem;
    font-size: 0.9rem;
  }

  .p-tabview.p-tabview-top > .p-tabview-nav {
    padding-top: 0;
    border: 0;
  }


  .guid {
    font-size: 10px;
    color: var(--lc2-color-foreground-disabled);
    white-space: normal;
  }

  .alert {
    padding: .75rem .75rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: small;
  }

  .alert-success {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
  }

  .alert-info {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f;
  }

  .alert-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b;
  }

  .alert-danger {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
  }

  ngx-loading {
    z-index: 9999;
  }

  .readonly-form {
    overflow-x: auto;

    .row {
      margin-bottom: .4rem;
      display: flex;
    }

    .label {
      white-space: nowrap;
      &.mw-20 {
        min-width: 20%;
      }
      &.mw-25 {
        min-width: 25%;
      }
      &.mw-30 {
        min-width: 30%;
      }
      &.mw-40 {
        min-width: 40%;
      }
      &.mw-60 {
        min-width: 60%;
      }
      &.mw-80 {
        min-width: 80%;
      }
      color: var(--lc-color-darkgray);
      font-size: 0.8rem;
      text-overflow: ellipsis;
    }
    .value {
      width: 100%;
      font-weight: 600;
      font-size: 0.85rem;
    }
  }

  app-root {

    .tree {
      min-width: 17rem;
    }

    @media screen and (max-width: 599px) {
      .tree {
        min-width: 15rem;
      }
      .order {
        min-width: calc(100% - 11vw);
      }
    }
    @media screen and (min-width: 600px) {
      .tree {
        min-width: 16rem;
      }
      .order {
        min-width: 32rem;
      }
    }

    @media screen and (min-width: 960px) {
      .tree {
        min-width: 18rem;
      }
    }

    .p-tree {
      width: 100%;
      height: 100%;
    }

    .collapsed {
      min-width: 3rem;
    }

    .card-list {
      max-width: calc(100vw - 2rem);
      width: 100%;
      height: calc(100% - 2rem);
      margin: 0;

      .p-card {
        height: 100%;
        padding: 1.5rem;

        .p-card-header {
          font-size: 1.5rem;
          margin-bottom: 0;
          .title {
            margin: auto 1rem auto 0;
            white-space: nowrap;
          }

          .input {
            font-size: 1.1rem;
            font-weight: initial;
          }

          .small-reset {
            position: absolute;
            right: 4px;
            top: 8px;
            font-size: 90%;
            color: rgba(0, 0, 0, 0.85);
            z-index: 999;
            cursor: pointer;
          }
        }
        .p-card-body {
          position: relative;
          padding: 0;
          height: calc(100% - 2rem);
          .p-card-content {
            height: 100%;

            .p-tabview .p-tabview-panels .p-tabview-panel {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    .gutter.gutter-horizontal {
      border-left: 1px dashed gainsboro;
      border-right: 1px dashed gainsboro;
      cursor: col-resize;
    }
  }

  .p-multiselect-no-header .p-multiselect-header {
    display: none;
  }

  .no-items-label {
    color: var(--lc-color-darkgray);
    font-size: 1.5rem;
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.grid-field {
  position: relative;
  height: 100%;

  > .flex-row:first-child {
    height: 36px;
  }

  > .label,
  > .flex-row .label {
    color: var(--lc2-color-foreground);
    display: block;
    font-size: 1rem;
    line-height: 36px;
  }
}

.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}

// Overlays

.camera-capture {
  background-color: black;
  height: 100%;
  overflow: hidden;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    object-fit: scale-down;
  }
  button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: transparent;
  }
}

.iv-fullscreen {
  z-index: 3001;

  .iv-fullscreen-close {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 19px;
    width: 32px;
    height: 32px;
  }
}

.iv-snap-view {
  top: auto;
  left: auto;
  bottom: 30px;
  right: 20px;
  opacity: 1 !important;
  pointer-events: inherit !important;
  background: rgba(50, 50, 50, 0.4);
}


.actions-overlay {
  right: 1rem;
  left: unset !important;
}

.breadcrumb-overlay {
  width: 390px;

  .container {
    position: relative;
    min-height: 32px;
    max-height: 80vh;
    height: 100%;
    overflow: auto;

    .p-tree {
      width: 100%;
    }
    .p-listbox {
      width: 100%;
    }
  }

  .overlay-buttons {
    position: absolute;
    top: .9rem;
    right: 2.5rem;
  }
}

.complex-variable-overlay {
  right: 4px;
  left: unset !important;
}
.search-overlay {
  left: 50% !important;
  transform: translateX(-50%) !important;
  &:after, &:before {
    content: unset
  }
  .p-overlaypanel-content {
    padding: 0 !important;
  }
}
.support-overlay {
  width: 400px;
  padding-bottom: 16px;

  textarea {
    width: 100%;
  }
}
.variable-toolbox-overlay {
  border-radius: 12px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.2);
  height: auto;
  max-height: calc(100vh - 20rem);
  overflow: auto;
  .p-overlaypanel-content {
    padding: 8px
  }
}


/** Field Value variants **/
.field {
  .value.static .input {
    background-color: transparent;
  }

  .value.static .label {
    background-color: #ededed;
    border-color: #b7b7b8;
  }

  .value.static .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-static.svg);
  }

  .value.variable.unset .label {
    background-color: #fce2cd;
    border-color: #ffb65f;
  }

  .value.variable.unset .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-variable-unset.svg);
  }

  .value.variable.simple .label {
    background-color: #fac77f;
    border-color: #f5a639;
  }

  .value.variable.simple .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-variable-simple.svg);
  }

  .value.object .label {
    background-color: #c5ffc9;
    border-color: #00c955;
  }

  .value.object .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-object.svg);
  }

  .value.text .label {
    border-color: #00c8c8;
    background-color: #c9ffff;
  }

  .value.text .label:after {
    content: ' _ ';
    margin-left: 12px;
    color: #c9ffff;
    display: block-inline;
    width: 10px;
    height: 10px;
    background-image: url(../assets/img/studio/fields/bg-value-text.svg);
    background-origin: content-box;
    background-position: center;
    background-size: 10px;
    background-repeat: no-repeat;
  }

  .value.text .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-text.svg);
  }

  .value.solution-type .label {
    padding: 5px 0px 5px 0px;
    background-color: #5dc4c4;
    border-color: #116e6e;
    border-right: 0 !important;
  }

  .value.solution-type .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-solution-type.svg);
  }

  .value.solution-type .member {
    background-color: #fff;
    border-color: #116e6e;
    border-left: 0 !important;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value.solution-type .member a {
    background-image: url(../assets/img/studio/fields/bg-connection-member-solution-type.svg);
  }

  .value.complex .label {
    padding: 5px 0px 5px 0px;
    background-color: #e6cfff;
    border-color: #ac40ff;
    border-right: 0 !important;
  }

  .value.complex .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-complex.svg);
  }

  .value.complex .member {
    background-color: #fff;
    border-color: #ac40ff;
    border-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value.complex .member a {
    background-image: url(../assets/img/studio/fields/bg-connection-member-complex.svg);
  }

  .value.complex2 .label {
    padding: 5px 0px 5px 0px;
    background-color: #ffd2d2;
    border-color: #ff4343;
    border-right: 0 !important;
  }

  .value.complex2 .connection {
    background-image: url(../assets/img/studio/fields/bg-connection-value-complex2.svg);
  }

  .value.complex2 .member {
    background-color: #fff;
    border-color: #ff4343;
    border-left: 0 !important;
  }

  .value.complex2 .member a {
    background-image: url(../assets/img/studio/fields/bg-connection-member-complex2.svg);
  }
}



mwl-calendar-tooltip-window {
  .cal-tooltip-inner {
    text-align: left;
    max-width: none;

    div:not(:first-child) {
      border-top: 1px solid white;
      padding-top: 4px;
    }

    .title {
      font-size: 1rem;
      font-weight: bold;
      line-height: normal;
      padding: 0;
      margin: 0;
      margin-bottom: 4px;
    }

    .info {
      font-size: .9rem;
      line-height: normal;
      padding: 0;
      margin: 0;
      margin-bottom: 4px;
      white-space: nowrap;
    }
  }
}
